import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'fury-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss'],
  host: {'class': 'fury-alert-box'},
  encapsulation: ViewEncapsulation.None,
})

export class FuryAlertBoxComponent {
  @Input() class: 'success' | 'info' | 'warn' | 'border' | 'warn-absolute' | 'info-absolute' | 'with-button' | 'without-border-radius' = 'info';
}
